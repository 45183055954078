import React from 'react';
import {Modal} from 'react-bootstrap';
import CallIcon from '@material-ui/icons/Call';
import CallEndIcon from '@material-ui/icons/CallEnd';
import style from './incoming-call-modal.module.scss';

interface IProps  {
    isVisible : boolean;
    setVisible : (a: boolean) => any;
    caller : any;
    answerPhone : () => void;
    dismissCall : () => void;
}

const CallModal = (props : IProps) => {
        
    const {caller, answerPhone, dismissCall} = props;

    return (
        <Modal centered  backdrop="static"   show={props.isVisible} onHide={() => props.setVisible(false)}>
        <Modal.Body className={`${style['modal']} flex-center flex-column`}>
         
          <div className="t-center">
            <h3 className="mb-10">Połączenie przychodzące</h3>
            <h2 className="mb-20">{caller ?
             ( caller
                .replace("sip:", "")
                .replace("@46.242.131.202", "")
                .replaceAll('"', ""))
                : '600472249'
             }
             </h2>
          </div>
          <div className="t-center">
            <CallIcon
              id="make_call"
              className={`${style['phone-action']} green`}
              onClick={answerPhone}
              
            />
            <CallEndIcon
              id="make_call"
              className={`${style['phone-action']} red`}
              onClick={dismissCall}
              
            />
            
          </div>
        
      </Modal.Body>
              </Modal>
    )
}

export default CallModal;