import React, { useEffect, useState, useRef, RefObject } from "react";
import { Formik, FormikProps, useFormikContext } from "formik";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { IUnassignedTask } from "types/task-types";
import Table from "components/common/table";
import { Column } from "react-table";
import FormButton from "components/buttons/form-button";
import ActionsDropdown from "components/common/actions-dropdown";
import style from "./unassigned-tasks.module.scss";

import CheckFormField from "components/form-fields/check-form-field";
import { IListing } from "types/async-types";
import { IService } from "types/agent-types";
import { formatDateTime } from "utils/date";
import { fetchUndelegatedServices } from "actions/services";

const UnnasignedTasks = (props: {
  onSubmitSelected: any;
  tasks: IListing<IService> | null;
  onToggleEdit : (a :any) => any;
  fetchData: typeof fetchUndelegatedServices;
  setSelectedValues : React.Dispatch<React.SetStateAction<{ client_ids: string[] } | undefined>>;
}) => {
  const { onSubmitSelected, tasks, onToggleEdit, fetchData, setSelectedValues } = props;
  const formRef = useRef<FormikProps<any>>(null);
  // const formikProps : FormikProps<any> = formRef.current;

  const bulkAssign = (values: any) => {
    onSubmitSelected(values, formRef.current);
  };
  const selectAll = (e: any) => {
    if (e.target.checked && tasks) {
      const ids = tasks.list.map((item, index) => {
        return item.id;
      });
      

      formRef.current?.setFieldValue("client_ids", ids);
    } else {
      formRef.current?.setFieldValue("client_ids", []);
    }
  };

  useEffect(() => {
      console.log('values changed');
  }, [formRef.current?.values])

  const unnasignedCols: Column<IService>[] = React.useMemo(
    () => [
      {
        Header: "Klient lub numer sprawy",
        accessor:  "customer_name",
        Cell: (props) => (
          <div className="flex-left">
            <CheckFormField
              id={props.row.original.id}
              name="client_ids"
              value={props.row.original.id}
              onCheckChange={() => { setSelectedValues(formRef.current?.values)}}
            />{" "}
            {props.value}{" "}
          </div>
        ),
      },
      {
        Header: "Kategoria",
        accessor: "title",
        Cell: (props) => <div className="md-con-t dark1">{props.value}</div>,
      },
      {
        Header: "Zleceniodawca",
        accessor: "company_name",
      },
      {
        accessor: "id",
        className: "b-left",
        
        Cell: (props) => {
          return (
            
              <div className="flex" onClick={() => onToggleEdit({ client_ids: [props.value] })}>
                <label
                  // onClick={() =>
                  //   onSubmitSelected({ client_ids: [props.value] })
                  // }
                  className="link grow-1"
                >
                  Przydziel agenta lub team{" "}
                </label>{" "}
                <ArrowDropDownIcon />{" "}
              </div>
            
          );
        },
      },
    ],
    []
  );

  const Effects = () => {
    const formikProps = useFormikContext<any>();

    useEffect(() => {
       setSelectedValues(formikProps.values)
    }, [formikProps.values]);
    return null;
  }
  return (
    <Formik
      innerRef={formRef}
      initialValues={{ client_ids: [] }}
      onSubmit={bulkAssign}
    >
      {(formikProps: FormikProps<any>) => {
        console.log("form values: ", formikProps.values);
        return (
          <div className="panel no-padding mb-20">
            <Effects />
          <div className="border-b relative">
         
            <Table columns={unnasignedCols} data={tasks} fetchData={fetchData} />
            <div className={style["bulk-action"]}>
              <CheckFormField
                className="mb-0"
                isFlag={true}
                onCheckChange={(e) => selectAll(e)}
                name="select_all"
                cbxLabel=""
                value="all"
                id="select_all"
              />
              <label className={`md-t ${style["count"]}`}>
                Zaznaczono: {formikProps.values["client_ids"].length}
              </label>{" "}
              <FormButton
                color="blue-color"
                className="sm"
                width={200}
                disabled={
                  formikProps.values["client_ids"].length === 0 ? true : false
                }
                title="Przydziel zaznaczone"
                onClick={formikProps.submitForm}
              />
            </div>
          </div>
          </div>
        );
      }}
    </Formik>
  );
};

export default UnnasignedTasks;
