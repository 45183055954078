import React, { useState, useEffect } from "react";
import {
  ITextComponent,
  IVerificationFormComponent,
  IStepComponent,
  IInvoiceSearchFormComponent,
  IFormButtonsComponent,
  StepComponentType,
  StepActionType,
  IIssue,
  IIssueStep,
  ITextInputComponent,
  IQuestionnaireRadioComponent,
} from "types/issue-types";
import TextComponent from "components/issues/dedicated-components/text";
import VerificationForm from "components/issues/dedicated-components/verification-form";
import InvoiceSearchForm from "components/issues/dedicated-components/invoice-search-form";
import FormButtons from "components/issues/dedicated-components/form-buttons";
import QuestionnaireRadio from "components/issues/dedicated-components/questionnaire-radio";
import StatementSummary from "components/issues/dedicated-components/statement-summary";
import Input from "components/issues/dedicated-components/input";
import MultilineText from "components/issues/dedicated-components/multiline-text";
import InitializeCall from "components/issues/dedicated-components/initialize_call";
import ServiceVisitForm from 'components/issues/dedicated-components/service-visit-form';
import RepaymentTermsForm from 'components/issues/dedicated-components/repayment-terms-form';
import MonitCreator from 'components/issues/dedicated-components/monit-creator';
import AssignCustomerForm from 'components/issues/dedicated-components/assign_customer_form';
import Email from 'components/issues/dedicated-components/email'
import { FormikProps } from "formik";

const DedicatedComponent = ({
  props,
  nextStep,
  stepAction,
  issue,
  step,
}: {
  props: StepComponentType;
  nextStep?: (stepId: string) => void;
  stepAction: (
    actionType: StepActionType,
    formikProps: FormikProps<any>
  ) => any;
  issue: IIssue;
  step: IIssueStep;
}) => {
  switch (props.type) {
    case "text":
      return <TextComponent {...(props as ITextComponent)} />;
      case "test":
        return <TextComponent {...(props as ITextComponent)} />;
      // return <Email />;
      case "assign_cbk_client":
        return (
          <AssignCustomerForm
          component={props as IVerificationFormComponent}
          nextStep={nextStep as () => void}
          stepAction={stepAction}
          issue={issue}
          step={step}
        />
        );
      case "monit_creator":
        return (
          <MonitCreator
          component={props as IVerificationFormComponent}
          nextStep={nextStep as () => void}
          stepAction={stepAction}
          issue={issue}
          step={step}
        />
        );
      case "statement_summary":
        return (
          <StatementSummary
          component={props as IVerificationFormComponent}
          nextStep={nextStep as () => void}
          stepAction={stepAction}
          step={step}
          issue={issue}
        />
        );
      case "repayment_terms":
        return (
          <RepaymentTermsForm
            component={props as any}
            nextStep={nextStep as () => void}
            stepAction={stepAction}
            step={step}
            issue={issue}
          />
        );
      case "initialize_call":
        return (
          <InitializeCall
            component={props as IVerificationFormComponent}
            nextStep={nextStep as () => void}
            stepAction={stepAction}
            // step={step}
            issue={issue}
          />
        );
    case "verification_form":
      return (
        <VerificationForm
          component={props as IVerificationFormComponent}
          nextStep={nextStep as () => void}
          stepAction={stepAction}
          step={step}
          issue={issue}
        />
      );
    case "invoice_search_form":
      return (
        <InvoiceSearchForm
          component={props as IInvoiceSearchFormComponent}
          step={step}
        />
      );
    case "form_buttons":
      if (issue.status !== "closed" && step.status !== "completed" && step.status !== "omitted") {
        return (
          <FormButtons
            component={props as IFormButtonsComponent}
            nextStep={nextStep as () => void}
            stepAction={stepAction}
          />
        );
      } else {
        return <> </>;
      }
    case "questionnaire_radio":
      return (
        <QuestionnaireRadio component={props as IQuestionnaireRadioComponent} />
      );

    case "input":
      return (
        <MultilineText
          component={props as ITextInputComponent}
          nextStep={nextStep as () => void}
        />
          //  <Email />
        
      );
     

      case "service_visit_form":
        return (
          <ServiceVisitForm
            component={props as IStepComponent}
            
          />
        );
    default:
      // case "input":
      //   const component :  IMultilineComponent  = props;
      //   component
      //   return (
      //     <Input
      //       component={props as IStepComponent}
      //       nextStep={nextStep as () => void}
      //     />
      //   );
      // default:
      return <div>no component</div>;
  }
};

export default DedicatedComponent;
