import React, { useState, useEffect, useCallback } from "react";
import Header from "components/header";
import LeftPanel from "components/left-panel/left-panel";
import ServicePanel from "components/service-panel/service-panel";
import Button from "components/buttons/button";
import Cases from "components/cases/issues-tabs";
import Spinner from "components/common/spinner";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";
import Settlements from "components/customer-data/settlements";
import ServiceMessage from 'components/header/Service-message';
import { IReduxActionState } from "types/async-types";
import { useReduxAction } from "utils/async-action";
import { fetchCustomer } from "actions/customer";
import { endService } from "actions/services";

type PreviewSectionType = "invoices" | "history" | undefined;

const ServiceDetails = () => {
  const [customerSection, setCustomerSection] = useState<PreviewSectionType>(
    undefined
  );
  const async = useSelector((state: RootState) => state.async);
  const service = useSelector((state: RootState) => state.service);
    
  const [endServiceState, execEndService]: readonly [
    IReduxActionState<any>,
    (serviceId: string, a2? :boolean) => any
  ] = useReduxAction(endService, "customer-service");



  const toggleSection = (section: PreviewSectionType) => {
    if (section === customerSection) {
      setCustomerSection(undefined);
    } else {
      setCustomerSection(section);
    }
  };
  const showCustomerPreview = () => {
    switch (customerSection) {
      case "invoices":
        return <Settlements />;
    }
  };

  const closeService = useCallback(() => {
    if (service?.currentService) {
      const accepted = window.confirm("Potwierdź zakończenie obsługi");
      if (accepted) {
        execEndService(service.currentService.id, true);
      }
    }
  }, [service?.currentService]);




  return (
    <>
        
      {/* {!service?.customer_verified &&
        <ServiceMessage />
      } */}
      
      <div className="px-3">
        <Header execEndService={closeService} />
        <div id="dashboard" className={`content ${service?.customer_verified ? "content--no-message" : ""}`}>
          <div className="row">
            <div className="col-4 h-100">
              <LeftPanel />
            </div>
            <div className="col-8 h-100">
              <div className="flex-column">
                {/* <p className="condensed dark md-t mb-10">Dane klienta</p> */}
                <div className="filters">
                  <div>
              
                    <Button
                    onClick={() => {}}
                      title="Historia usługi"
                      color="transparent"
                      inline={true}
                      
                    />
                    <Button
                    onClick={() => {}}
                      title="Bieżące rozliczenie"
                      // onClick={() => toggleSection("invoices")}
                      color={
                        customerSection === "invoices"
                          ? "black-color"
                          : "transparent"
                      }
                      inline={true}
                    />
                  
                    <Button     onClick={() => {}} title="Umowy" color="transparent" inline={true} />
                    {/* <Button
                      title="Urządzenia"
                      color="transparent"
                      inline={true}
                    />
                    <Button
                      title="Zamówienia"
                      color="transparent"
                      inline={true}
                    />
                    <Button
                      title="Notatki agentów"
                      color="transparent"
                      inline={true}
                    />
                    <Button
                      title="Dane personalne"
                      color="transparent"
                      inline={true}
                    />
                    <Button
                      title="Aktualne sprawy"
                      color="transparent"
                      inline={true}
                    /> */}
                  </div>
                </div>
                {/* <p className="md-con-t mb-10">
                Sprawy w obecnie trwającym kontakcie
              </p> */}

                {customerSection ? showCustomerPreview() : <Cases />}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ServiceDetails;
