import axios, { AxiosRequestConfig } from "axios";
import { API_BASE_URL } from "config";
import { ActionResponse } from "types/async-types";
import { ISessionData, ILoginData } from "types/session-types";
import {  requestHeaders } from "utils/auth";
import {
  IFetchSessionAction,
  FETCH_SESSION,
  FETCH_CUSTOMER,
  IFetchCustomerAction,
  FETCH_INVOICES,
  IFetchCustomerInvoicesAction,
  IFetchCustomerContacts,
  FETCH_CONTACTS,
  IFetchCustomerIssues,
  FETCH_ISSUES,
} from "types/redux-types";

const LOGIN_URL = `${API_BASE_URL}/login`;
const LOGOUT_URL = `${API_BASE_URL}/logout`;
const PASSWORD_RESETS_URL = `${API_BASE_URL}/password_resets`;
const USERS_URL = `${API_BASE_URL}/users`;

export const fetchCustomer = (phone?: String) => async (dispatch: any) => {
  const options: AxiosRequestConfig = {
    url: `${API_BASE_URL}/customers/find_customer`,
    method: "POST",
    headers: requestHeaders().headers,
    data: {
      phone_number: phone || "600472249",
    },
  };
  let res: ActionResponse<any> = await axios(options);
  if (res) {
    const action: IFetchCustomerAction = {
      type: FETCH_CUSTOMER,
      payload: res.data,
    };
    dispatch(action);
    return res;
  }
};

export const fetchCustomerById = (id: String) => async (dispatch: any) => {
  const options: AxiosRequestConfig = {
    url: `${API_BASE_URL}/customers/${id}`,
    method: "GET",
    headers: requestHeaders().headers,
    data: {
      id 
    },
  };
  let res: ActionResponse<any> = await axios(options);
  if (res) {
    const action: IFetchCustomerAction = {
      type: FETCH_CUSTOMER,
      payload: res.data,
    };
    dispatch(action);
    return res;
  }
};

export const fetchCustomers = async (query?: String) => {
  const options: AxiosRequestConfig = {
    url: `${API_BASE_URL}/customers/search`, 
    method: "POST",
    headers: requestHeaders().headers,
    data : {
      q : query
    }

  };
  let res: ActionResponse<any> = await axios(options); 
  if (res) {
      return res;
  }
};

export const fetchInvoices = async (uuid : string, date_from : string, date_to : string) => {
  const options: AxiosRequestConfig = {
    url: `${API_BASE_URL}/customers/invoices`,
    method: "POST",
    headers: requestHeaders().headers,
    data: {
      customer_uuid: uuid,
      date_from : date_from,
      date_to : date_to
    },
  };
  let res: ActionResponse<any> = await axios(options);
  if (res) {
    // const action: IFetchCustomerInvoicesAction = {
    //   type: FETCH_INVOICES,
    //   payload: res.data,
    // };
    // dispatch(action);
    return res;
  }
};

// export const fetchContacts = (uuid : string) => async (dispatch: any) => {
//   const options: AxiosRequestConfig = {
//     url: `${API_BASE_URL}/customers/${uuid}/contacts`,
//     method: "POST",
//     headers: requestHeaders().headers,
//     // data: {
//     //   customer_uuid: uuid
//     // },
//   };
//   let res: ActionResponse<any> = await axios(options);
//   if (res) {
//     const action: IFetchCustomerContacts = {
//       type: FETCH_CONTACTS,
//       payload: res.data,
//     };
//     dispatch(action);
//     return res;
//   }
// };

// export const fetchCustomerIssues = async (customerId : string) => {
//   const options: AxiosRequestConfig = {
//     url: `${API_BASE_URL}/customers/${customerId}/issues`,
//     method: "GET",

//     headers: requestHeaders().headers,
//   };
//   let res: ActionResponse<IIssueTemplate[]> = await axios(options);
//   return res;
// };

export const fetchCustomerIssues =  (customerId : string, page? : number, filter? : "open" | "closed") => async (dispatch: any) => {
  const options: AxiosRequestConfig = {
    url: `${API_BASE_URL}/customers/${customerId}/issues?page=${page}${filter ? `&filter=${filter}` : ""}`,
    method: "GET",
    headers: requestHeaders().headers,
  };
  let res: ActionResponse<any> = await axios(options);
  if (res) {
    const action: IFetchCustomerIssues = {
      type: FETCH_ISSUES,
      payload: res.data,
    };
    dispatch(action);
    return res;
  }
};

export const fetchContacts = (uuid : string, page? : number) => async (dispatch: any) => {
 
  const options: AxiosRequestConfig = {
    url: `${API_BASE_URL}/customers/${uuid}/contacts${page ? `?page=${page}}` : ""}`,
    method: "POST",
    headers: requestHeaders().headers,
    // data: {
    //   customer_uuid: uuid
    // },
  };
  let res: ActionResponse<any> = await axios(options);
  if (res) {
    const action: IFetchCustomerContacts = {
      type: FETCH_CONTACTS,
      payload: res.data,
    };
    dispatch(action);
    return res;
  }
};


