import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import {useFormikContext} from 'formik';
import "react-datepicker/dist/react-datepicker.css";

import CaseButton from "components/buttons/case-button";
import { IInvoiceSearchFormComponent, IFormButtonsComponent, IStepComponent, ITextInputComponent } from "types/issue-types";
import TextField from 'components/form-fields/text-field';

const InputComponent = ({component , nextStep} : {component : ITextInputComponent, nextStep : () => void}) => {

    const props = useFormikContext();
 
    useEffect(() => {
        // props.setFieldValue(component.name, "1234");
        props.setFieldValue(component.name, component.value);
    }, []);
    
  return (

    <TextField type="textarea" name={component.name} label={component.title || ""} />
   
  );
};

export default InputComponent;
