import React, { useState, useEffect } from "react";
import Button from "components/buttons/button";
import PhoneCallbackIcon from "@material-ui/icons/PhoneCallback";
import PhoneDisabledIcon from "@material-ui/icons/PhoneDisabled";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import { Pagination } from "@material-ui/lab";
import InfoIcon from "@material-ui/icons/Info";
import WarningIcon from "@material-ui/icons/Warning";
import MicIcon from "@material-ui/icons/Mic";
import LinkIcon from "@material-ui/icons/Link";
import RecordingModal from "./recording-modal";

import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import style from "./left-panel.module.scss";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";
import { fetchContacts, fetchCustomerIssues } from "actions/customer";
import { IReduxActionState, IActionState } from "types/async-types";
import { useReduxAction, useAsyncAction } from "utils/async-action";
import { IContact } from "types/customer-types";
import { formatDateTime, getTime } from "utils/date";
import {
  IIssueTemplate,
  IIssueLog,
  IIssue,
  IssueLogType,
} from "types/issue-types";
import {
  fetchIssue,
  addIssueToContact,
  syncAttachedIssues,
} from "actions/issues";
import Spinner from "components/common/spinner";
import { PhoneCallbackOutlined } from "@material-ui/icons";

const statusLabel: { [key: string]: string } = {
  started: "Rozpoczęto",
  not_started: "Nie rozpoczęto",
  completed: "Ukończono",
};

const Issues = () => {
  const [page, setPage] = useState(1);
  const [filter, setFilter] = useState<"open" | "closed" | "all">("open");
  const [recordingModalVisible, setRecordingModalVisible] = useState(false);
  const [logContactId, setLogContactId] = useState<string>();
  const [issueId, setIssueId] = useState<string>();
  const customer = useSelector((state: RootState) => state.customer);
  const service = useSelector((state: RootState) => state.service);
  const attachedIssues = customer?.attached_issues;
  const contacts = customer?.contacts;
  const currentIssue = useSelector(
    (state: RootState) => state.service?.currentIssue
  );

  useEffect(() => {
    console.log("currentIssueStatus: ", currentIssue);
    if(currentIssue){
      switch (currentIssue.issue_status) {
        case "open":
          filterIssues("open");
          break;
  
        case "closed":
          filterIssues("closed");
          break;
  
        case "terminated":
          filterIssues("closed");
          break;
  
        default:
          filterIssues("all");
      }
    }
  
  }, [currentIssue]);

  const [issuesState, execFetchCustomerIssues]: readonly [
    IReduxActionState<any>,
    (uuid: string, pageNo: number, filter?: "open" | "closed" | "all") => any
  ] = useReduxAction(fetchCustomerIssues, "issues");

  // const [issueState, execFetchCustomerIssue]: readonly [
  //   IReduxActionState,
  //   any
  // ] = useReduxAction(fetchIssue, "issue");

  const [contactIssueState, execAddIssueToContact]: readonly [
    IActionState<any>,
    typeof addIssueToContact
  ] = useAsyncAction(addIssueToContact);

  const [syncIssueState, execSyncAttachedIssues]: readonly [
    IReduxActionState<any>,
    any
  ] = useReduxAction(syncAttachedIssues, "attached-issues");

  useEffect(() => {
    if (customer) {
      execFetchCustomerIssues(customer.customer_uuid, 1, filter);
    }
  }, [customer?.customer_uuid]);

  useEffect(() => {
    if (customer && attachedIssues && Object.keys(attachedIssues).length > 0) {
      execFetchCustomerIssues(customer.customer_uuid, page, filter);
    }
  }, [attachedIssues]);

  const fetchIssuesPage = (pageNo: number) => {
    setPage(pageNo);
    if (customer) {
      execFetchCustomerIssues(customer.customer_uuid, pageNo, filter);
    }
  };
  const filterIssues = (filter: "open" | "closed" | "all") => {
    setFilter(filter);
    if (customer) {
      if (filter === "all") {
        execFetchCustomerIssues(customer.customer_uuid, 1);
      } else {
        execFetchCustomerIssues(customer.customer_uuid, 1, filter);
      }
    }
  };

  // **** SYNC TABS TRY *****
  // #INFO# Próby odczytania wysłanego statusu i aktualizacja widoku,
  // ## dodałem funkcję która miała wywoływać filtry itd, ale zapętla się reducer bo stany są zmieniają bardzo często.
  // ## nie wiem czy poprawnym jest odświeanie całego reduxa, bo dodanie useSelector z EQ_Function działa duo lepiej
  // ## mona tez uzyć rejestracji store.subscribe -- ale to dalej jest problem związany z tym ze masz jeden REDUX-STORE czyli kazda zmiana wywoluje subskrybenta!
  // ## to co zauwazylem to bardzo, bardzo duzo zmian leci w trakcie przełączania się między service i issue

  // const [issue_stat, set_issue_stat] = useState("all");

  // const issueId = useSelector() => ({issue_status}, shallowEqual)

  // const issueIds = useSelector((state : RootState) => {
  //   console.log(state.customer?.customer_uuid);

  //   return state.service?.currentService?.issue_status;
  // }, (left, right) => {
  //   console.log(`update2 ${left}  / ${right}`);
  //   if ( left === right){
  //     console.log("EQ");

  //     return true;
  //   } else {
  //     changeIt(left || '');
  //     return false;
  //   }
  // })

  // function changeIt(currentIssuestatus: string){
  //   if(currentIssuestatus === issue_stat) {
  //     console.log('RET');

  //     return
  //   }
  //   console.log(customer);

  //   set_issue_stat(currentIssuestatus || '');
  //   console.log(`Change ${currentIssuestatus}`);

  //   switch(currentIssuestatus){
  //     case 'open': {
  //       return filterIssues('open');
  //     }
  //     case 'closed' || 'terminated': {
  //       return filterIssues('closed');
  //     }
  //     default: return filterIssues('all');
  //   }
  // }

  // **** END SYNC TABS TRY *****

  const renderLog = (log: IIssueLog[], issue: IIssue) => {
    const showRecordingModal = (contactId: string, issueId: string) => {
      setLogContactId(contactId);
      setIssueId(issueId);
      setRecordingModalVisible(true);
    };

    const result = log.map((item, index) => {
      const renderIcon = (type: IssueLogType) => {
        switch (type) {
          case "INFO":
            return <InfoIcon />;
          case "WARNING":
            return <WarningIcon />;
          case "RECORD":
            return <MicIcon className="red" />;
          case "SUCCESS":
            return <CheckCircleOutlineIcon />;
          case "LINK":
            return <LinkIcon />;
          case "CALL":
            return <PhoneCallbackOutlined />;
        }
      };

      const renderLink = (logItem: IIssueLog) => {
        switch (logItem.log_type) {
          case "RECORD":
            return (
              <a
                target="_blank"
                onClick={() =>
                  showRecordingModal(logItem.contact_id, issue.uuid)
                }
                rel="noreferrer"
              >
                {item.content}
              </a>
            );

          case "LINK":
            if (logItem.url) {
              return (
                <a target="_blank" href={logItem.url} rel="noreferrer">
                  {item.content}
                </a>
              );
            }
            break;
          default:
            return item.content;
        }
      };

      return (
        <div key={item.date_time + "_log"} className={`${style["log"]}`}>
          <ul className={`mb-10`}>
            <li className="flex-left">
              <div className="md-con-t mr-5">
                {" "}
                {formatDateTime(item.date_time)}
              </div>
              <div className={`md-con-t ${style["time"]}`}>
                {" "}
                {getTime(item.date_time)}
              </div>

              <div className={`${style["action-icon"]}`}>
                {renderIcon(item.log_type)}
                {/* <PhoneDisabledIcon className="grey" /> */}
              </div>
              <div
                className={`md-con-t grow-1 ${style["log-details"]} ${item.log_type}`}
              >
                {renderLink(item)}
              </div>
            </li>
          </ul>
        </div>
      );
    });

    return <div className="mt-10">{result}</div>;
  };

  const SimpleIssueItem = (props: { issue: IIssue }) => {
    const [expanded, setExpanded] = useState<boolean>(false);
    const [attached, setAttached] = useState<boolean>(false);

    const { issue } = props;

    const toggleIssue = async () => {
      if (service?.currentService && customer) {
        const res = await execAddIssueToContact(
          customer.customer_uuid,
          issue.uuid,
          service?.currentService?.contact_id
        );
        if (res.status === 200 || res.status === 201) {
          // execFetchCustomerIssue(customer?.customer_uuid, issue.uuid);
          execSyncAttachedIssues(customer?.customer_uuid, issue.uuid);
        }
      }
    };

    useEffect(() => {
      const currentIssueId = currentIssue?.issue_id;
      if (attachedIssues && issue.uuid === currentIssueId) {
        setExpanded(true);
      }
      if (attachedIssues && attachedIssues[issue.uuid]) {
        setAttached(true);
      }
    }, []);

    // useEffect(() => {
    //   if (attachedIssues && attachedIssues[issue.uuid]) {
    //     setExpanded(true);
    //   }
    // }, []);

    return (
      <div
        className={`${style["call-item"]} ${
          attached ? style["expanded"] : ""
        } flex`}
      >
        {attached && (
          <div
            className="item-marker"
            style={{ backgroundColor: `${issue.color}` }}
          ></div>
        )}
        {issue.status === "closed" && (
          <CheckCircleOutlineIcon className=" green" />
        )}
        {issue.status === "open" && (
          <img src="/assets/images/progress-check.svg" className="mr-10" />
        )}
        {issue.status === "terminated" && (
          <HighlightOffIcon className="red mr-10" />
        )}

        <div className="grow-1">
          <div
            onClick={() => setExpanded(!expanded)}
            className={`flex-center ${style["overview"]}`}
          >
            <div className="grow-1">
              <div className="md-con-t">
                {/* <span className="md-con-t mr-5">
                  {formatDateTime(contact.created_at)}
                </span> */}
                {<div className="md-t dark">{issue.number}</div>}

                {issue.title}

                {/* <span className="green condensed">(trwająca…)</span> */}
              </div>
              <div className="md-t">{issue.title.split(">").slice(-1)[0]}</div>
            </div>

            <div className={`${style["case-count"]} md-con-t`}>
              {/* <div>expanded: {expanded.valueOf().toString()} </div>
              {issue.uuid} */}
              {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </div>
          </div>
          {expanded && renderLog(issue.log, issue)}
          {expanded && (
            <div className={`md-con-t ${style["links"]}`}>
              {(!attachedIssues ||
                (attachedIssues && !attachedIssues[issue.uuid])) &&
                issue.status === "open" && (
                  <a onClick={() => toggleIssue()} href="#">
                    Przypnij do trwającego kontaktu
                  </a>
                )}
            </div>
          )}
        </div>
      </div>
    );
  };

  const renderIssues = () => {
    if (customer?.issues) {
      return customer.issues.issues.map((item, index) => {
        return <SimpleIssueItem key={item.uuid + "_issue"} issue={item} />;
      });
    }
  };

  // useEffect(() => {
  //   if (customer && customer.contact_uuid) {
  //     execFetchCustomerIssues(customer.customer_uuid, 1);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [customer?.contact_uuid]);

  return (
    <>
      <div className={`panel-p  ${style["issue-filters"]}`}>
        <div>
          <Button
            onClick={() => filterIssues("all")}
            title="Wszystkie"
            color={filter === "all" ? "black-color" : "grey-color"}
            inline={true}
          />
          <Button
            onClick={() => filterIssues("open")}
            title="Otwarte"
            color={filter === "open" ? "black-color" : "grey-color"}
            inline={true}
          />
          <Button
            onClick={() => filterIssues("closed")}
            title="Zamknięte"
            color={filter === "closed" ? "black-color" : "grey-color"}
            inline={true}
          />
        </div>
      </div>
      {(issuesState.loading ||
        contactIssueState.loading ||
        syncIssueState.loading) && <Spinner position="relative" />}
      {customer?.issues && (
        <div className="pagination-wrapper">
          <Pagination
            shape="rounded"
            onChange={
              fetchIssuesPage ? (e, page) => fetchIssuesPage(page) : undefined
            }
            page={customer?.issues?.current_page}
            count={customer.issues?.total_pages}
          />
        </div>
      )}

      <div className={`panel-p issues-sections`}>{renderIssues()}</div>
      <RecordingModal
        isVisible={recordingModalVisible}
        setVisible={setRecordingModalVisible}
        contactId={logContactId}
        issueId={issueId}
      />
    </>
  );
};

export default Issues;
