import React, { useState, useEffect, useRef } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import IssueDetails from "./issue-details";

import Spinner from "components/common/spinner";
import CloseIcon from "@material-ui/icons/Close";
import style from "./cases.module.scss";
import {
  IReduxActionState,
  IActionState,
  ActionResponse,
} from "types/async-types";
import { useReduxAction, useAsyncAction } from "utils/async-action";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "redux/reducers";
import {
  fetchIssueTemplate,
  createNewIssue,
  attachIssue,
  destroyIssue,
  syncAttachedIssues,
} from "actions/issues";
import { IIssueTemplate, IIssue } from "types/issue-types";
import IssuesModal from "components/cases/issues-modal";
import { IUpdateIssueId, UPDATE_ISSUE } from "types/redux-types";
import { compareSteps } from "utils/helpers";
import { useReduxActions } from "redux/actions";

const Cases = (props: any) => {
  const [currentTab, setCurrentTab] = useState<number>(0);
  const [issuePickVisible, setIssuePickVisible] = useState(false);
  const async = useSelector((state: RootState) => state.async);
  const customer = useSelector((state: RootState) => state.customer);
  const serviceState = useSelector((state: RootState) => state.service);
  const currentIssue = useSelector((state: RootState) => state.service?.currentIssue);
  const {updateCurrentIssue} = useReduxActions();

  const dispatch = useDispatch();

  // const [issues, setIssues] = useState<IIssue[]>([]);
  const issues = customer?.attached_issues;

  // const [templatesState, execFetchTemplates]: readonly [
  //   IActionState<IIssueTemplate[]>,
  //   () => any
  // ] = useAsyncAction(fetchIssueTemplates);

  const [templateState, execFetchTemplate]: readonly [
    IActionState<IIssueTemplate>,
    typeof fetchIssueTemplate
  ] = useAsyncAction(fetchIssueTemplate);

  const [newIssueState, execCreateNewIssue]: readonly [
    IActionState<IIssue>,
    typeof createNewIssue
  ] = useAsyncAction(createNewIssue);

  const [destroyState, execDestroyIssue]: readonly [
    IReduxActionState<any>,
    any
  ] = useReduxAction(destroyIssue, "attached-issues");



  const [syncIssueState, execSyncAttachedIssues]: readonly [
    IReduxActionState<any>,
    any
  ] = useReduxAction(syncAttachedIssues, "attached-issues");



  useEffect(() => {
    if (customer?.starting_issue_uuid) {
      execSyncAttachedIssues(
        customer?.customer_uuid,
        customer.starting_issue_uuid
      );
      // execFetchCustomerIssue(customer?.customer_uuid, customer.starting_issue_uuid);
    }
  }, [customer?.starting_issue_uuid]);

  useEffect(() => {
    if (newIssueState.data) {
      dispatch(attachIssue(newIssueState.data));
      updateCurrentIssue({ // redux action
        issue_id: newIssueState.data.uuid,
        issue_status: newIssueState.data.status,
      });
    }
  }, [newIssueState.data]);

  const addIssue = async (uuid: string) => {
    setIssuePickVisible(false);
    const res: ActionResponse<IIssueTemplate> = await execFetchTemplate(uuid);
    if (customer && res.data) {
      // commented out for test
      if (serviceState?.currentService?.contact_id) {
        execCreateNewIssue(
          customer.customer_uuid,
          serviceState?.currentService?.contact_id,
          res.data.uuid
        );
      }
    }
  };

  const deleteDraft = (issueId: string) => {
    if (customer) {
      execDestroyIssue(customer.customer_uuid, issueId);
    }
  };

  useEffect(() => {
    const issueId = currentIssue?.issue_id;

    if (issues && issueId) {
      let issuesArray = Object.keys(issues);
      setCurrentTab(issuesArray.indexOf(issueId));
    }
  }, [
    currentIssue?.issue_id,
    issues,
  ]);

  const renderTabs = () => {
    if (issues) {
      let issuesArray = Object.keys(issues);
      const currentIssueId = serviceState?.currentService?.issue_id;

      // if (currentIssueId && issues[currentIssueId]) {
      //   issuesArray = issuesArray.filter((item) => item !== currentIssueId);
      //   issuesArray.unshift(currentIssueId);
      // }

      return issuesArray.map((key, index) => {
        return (
          <Tab key={issues[key].uuid + "_tab"}>
            {/* <div style={{ fontSize: "8px" }}> {issues[key].uuid} </div> */}
            <div
              className="item-marker"
              style={{ backgroundColor: `${issues[key].color}` }}
            ></div>
            <div className="flex-center grow-1">
              <h2 className="mr-5">{issues[key].title.split(">")[1]}</h2>
              {issues[key].status === "draft" && (
                <CloseIcon onClick={() => deleteDraft(issues[key].uuid)} />
              )}
            </div>
          </Tab>
        );
      });
    }
  };

  const renderTabPanels = () => {
    if (issues) {
      let issuesArray = Object.keys(issues);
      const currentIssueId = serviceState?.currentService?.issue_id;

      // if (currentIssueId && issues[currentIssueId]) {
      //   issuesArray = issuesArray.filter((item) => item !== currentIssueId);
      //   issuesArray.unshift(currentIssueId);
      // }

      return issuesArray.map((key, index) => {
        const template: IIssue = {
          ...issues[key],
          steps: issues[key].steps.sort(compareSteps),
        };
        return (
          <TabPanel
            className={`${style["issue-tab"]}`}
            key={issues[key].uuid + "_panel"}
          >
            <IssueDetails issue={template} />
          </TabPanel>
        );
      });
    }
  };

  // useEffect(() => {
  //   if (customer?.issue) {
  //       dispatch(attachIssue(customer.issue))
  //   }
  // }, [customer?.issue]);

  return (
    <div className="relative panel no-padding flex-1">
      {(newIssueState.loading ||
        destroyState.loading ||
        (async.isLoading && async.property === "attached-issues")) && (
        <Spinner />
      )}
      <IssuesModal
        addIssue={addIssue}
        show={issuePickVisible}
        onHide={() => setIssuePickVisible(false)}
      />
      {!customer ? (
        <h1 className="flex-center h-100">Oczekiwanie na połączenie</h1>
      ) : (
        <Tabs
          selectedIndex={currentTab}
          forceRenderTabPanel={true}
          onSelect={(ind, last, event) => {
            if (issues) {
              let issuesArray = Object.keys(issues);

              issuesArray.forEach(function (key, index) {
                if (index === ind && issues) {
                  const currentIssueData = {
                    issue_id: issues[key].uuid,
                    issue_status: issues[key].status,
                  };
                  updateCurrentIssue(currentIssueData) //redux action
                }
              });
            }
            setCurrentTab(ind);
          }}
        >
          <div
            onClick={() => setIssuePickVisible(true)}
            className={` ${style["add-case"]}`}
          >
            <AddCircleOutlineIcon className="green" />
          </div>
          <TabList className={`tablist ${style["tablist"]}`}>
            {/* <Tab>
            <h2>Windykacja</h2>
            <h3>Bieżące rozliczenie</h3>
          </Tab> */}
            {renderTabs()}
          </TabList>
          {/* <TabPanel>
            <div  className={` ${style['case-breadcrumb']}`}>
                <span>Windykacja</span>
            </div>
            <CaseSteps />
        </TabPanel> */}
          {renderTabPanels()}
        </Tabs>
      )}
    </div>
  );
};

export default Cases;
