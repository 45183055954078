import React, { useEffect, useState, useRef } from "react";
import { Formik, FormikProps } from "formik";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { IUnassignedTask } from "types/task-types";
import Table from "components/common/table";
import { Column } from "react-table";
import FormButton from "components/buttons/form-button";
import ActionsDropdown from "components/common/actions-dropdown";
import style from "./unassigned-tasks.module.scss";

import CheckFormField from "components/form-fields/check-form-field";
import { IListing } from "types/async-types";
import { IService } from "types/agent-types";
import { formatDateTime } from "utils/date";
import { fetchUndelegatedServices } from "actions/services";
import TeamIcon from "components/common/team-icon";
import FiberManualRecord from '@material-ui/icons/FiberManualRecord';
import { setTaskColor } from "utils/task-helper";

const AssignedTasks = (props: {  

  tasks: IListing<IService> | null;
  
  fetchData: typeof fetchUndelegatedServices;
}) => {
  const { tasks, fetchData } = props;
  const formRef = useRef<FormikProps<any>>(null);
  const assignedCols: Column<IService>[] = React.useMemo(
    () => [
      {
        Header: "Klient lub numer sprawy",
        accessor: "customer_name",
      },
 
      {
        Header: "Kategoria",
        accessor: "title",
        Cell: (props) => <div className="md-con-t dark1">{props.value}</div>,
      },
      {
        Header: "Zleceniodawca",
        accessor: "company_name",
      },
      {
        Header: "Zaplanowany kontakt",
        accessor: "contact_date_time",
        Cell: (props) => (
          <div className="md-con-t dark1">{formatDateTime(props.value)}
            <FiberManualRecord htmlColor= { setTaskColor(props.value, props.row.original.actual_contact_date_time) }/>
          </div>
        ),
      },
      {
        Header: "Agent/Zespół",
        accessor: "user_name",
        className: "b-left",
        Cell: (props) => {
          return (
            <>
              {" "}
              {props.value || (
                <TeamIcon
                  name={props.row.original.team_name}
                  hex={props.row.original.team_color}
                  id={props.row.original.team_id}
                />
              )}
            </>
          );
        },
      },
    ],
    []
  );

  return (
    <div className="panel no-padding mb-20">
    <div className="border-b">
    
      <Table columns={assignedCols} data={tasks} fetchData={fetchData} />
    
    </div>
    </div>
 
  );
};

export default AssignedTasks;
