import React, { useState, useEffect, RefObject } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import Table from "components/common/table";
import TodoTasks from "./todo-tasks";
import EndedTasks from "./ended-tasks";
import CloseIcon from "@material-ui/icons/Close";
import Button from "components/buttons/button";
import TaskForm from "components/admin/tasks/task-form";
import Spinner from "components/common/spinner";
import UnassignedTasks from "components/admin/tasks/unassigned-tasks";
import AssignedTasks from "components/admin/tasks/assigned-tasks";
import { IActionState, IListing } from "types/async-types";
import { IUnassignedTask, IAssignedTask } from "types/task-types";
import { useAsyncAction } from "utils/async-action";
import TeamIcon from "components/common/team-icon";
import SelectField from 'components/form-fields/select-field';
import {CSVForm} from './csv-file-form';
import {
  fetchTodayServices,
  fetchUndelegatedServices,
  fetchOpenServices,
  createService,
  updateService,
  bulkAssignService,
  fetchServiceStats,
  fetchServices,
  uploadCSV,
} from "actions/services";
import { IService, IServiceStats, ITeamItem } from "types/agent-types";
import { IIssueTemplate } from "types/issue-types";
import { fetchCompanyTeams } from "actions/company-teams";
import { FormikProps, Formik } from "formik";

const Tasks = (props: any) => {
  const [activeTeam, setActiveTeam] = useState<string>();

  const [selectedValues, setSelectedValues] = useState<{
    client_ids: string[];
  }>();
  const [currentTab, setCurrentTab] = useState<number>(0);
  const [taskIds, setTaskIds] = useState<string[]>([]);
  const [selectedTasks, setSelectedTasks] = useState<string[]>([]);
  const [newTaskSiderVisible, setNewTaskSiderVisible] = useState(false);
  const [fileSiderVisible, setFileSiderVisible] = useState(false);
  const [assignTaskSiderVisible, setAssignTaskSiderVisible] = useState(false);
  const [editTaskSiderVisible, setEditTaskSiderVisible] = useState(false);
  const [unassignedFormikBag, setUnassignedFormikBag] = useState<FormikProps<any> | null | undefined>(null);


  useEffect(() => {
    // execFetchUnassignedTasks();
    // execFetchAssignedTasks();
    // execFetchStartedTasks();
    execFetchStats();
    execFetchTeams();
  }, []);

  const [teamsState, execFetchTeams]: readonly [
    IActionState<IListing<ITeamItem>>,
    typeof fetchCompanyTeams
  ] = useAsyncAction(fetchCompanyTeams);

  const [upladFileState, execUploadFile]: readonly [
    IActionState<any>,
    typeof uploadCSV
  ] = useAsyncAction(uploadCSV);

  const [serviceState, execCreateService]: readonly [
    IActionState<any>,
    (a: any) => any
  ] = useAsyncAction(createService);

  const [updateState, execUpdateService]: readonly [
    IActionState<any>,
    (...a: any) => any
  ] = useAsyncAction(updateService);

  const [bulkAssignState, execBulkAssignService]: readonly [
    IActionState<any>,
    (...a: any) => any
  ] = useAsyncAction(bulkAssignService);

  useEffect(() => {
    if (serviceState.data) {
      setNewTaskSiderVisible(false);
      execFetchStats();
      execFetchAssignedTasks();
      execFetchUnassignedTasks();
    }
  }, [serviceState.data]);

  useEffect(() => {
    if (updateState.data) {
      setEditTaskSiderVisible(false);
      execFetchStats();
      execFetchAssignedTasks();
      execFetchUnassignedTasks();
    }
  }, [updateState.data]);

  useEffect(() => {
    if (bulkAssignState.data) {
      setAssignTaskSiderVisible(false);
      execFetchStats();
      execFetchAssignedTasks();
      execFetchUnassignedTasks();
    }
  }, [bulkAssignState.data]);

  const [unassignedTasksState, execFetchUnassignedTasks]: readonly [
    IActionState<IListing<IService>>,
    typeof fetchUndelegatedServices
  ] = useAsyncAction(fetchUndelegatedServices);

  const [statsState, execFetchStats]: readonly [
    IActionState<IServiceStats>,
    typeof fetchServiceStats
  ] = useAsyncAction(fetchServiceStats);

  const [startedTasksState, execFetchStartedTasks]: readonly [
    IActionState<IListing<IService>>,
    any
  ] = useAsyncAction(fetchOpenServices);

  const [assignedTasksState, execFetchAssignedTasks]: readonly [
    IActionState<IListing<IService>>,
    any
  ] = useAsyncAction(fetchServices);

  const onTeamSelected = (values: { client_ids: string[] }) => {
    if (selectedValues) {
      setAssignTaskSiderVisible(true);
      setTaskIds(selectedValues.client_ids);
    }
  };

  const onSubmitSelected = (values: { client_ids: string[] }, formikBag? : FormikProps<any>) => {
    setAssignTaskSiderVisible(true);
    setTaskIds(values.client_ids);
    debugger;
    setUnassignedFormikBag(formikBag); 
  };

  const onEditSelected = (values: { client_ids: string[] }) => {
    setEditTaskSiderVisible(true);
    setTaskIds(values.client_ids);
  };

  const onCreateSubmit = (values: any) => {
    execCreateService(values);
  };

  const onAssignSubmit = async (values: any) => {
   const res = await execBulkAssignService({ ...values, customer_service_ids: taskIds });
   if(res.status === 200 || res.status === 201 ){
      unassignedFormikBag?.resetForm(); 
   }
  };

  const onEditSubmit = (values: any) => {
    execUpdateService(values, taskIds[0]);
  };

  const pickTeam = (teamId: string) => {
    if (selectedValues && selectedValues.client_ids.length > 0) {
      setActiveTeam(teamId);
      setAssignTaskSiderVisible(true);
      setTaskIds(selectedValues.client_ids);
    } else {
      alert("Wybierz przynajmniej jedno zadanie");
    }
  };

  const submitFile = (values: any) => {

    execUploadFile(values);

  }

  useEffect(() => {
    if(upladFileState.data){
      execFetchStats();
      execFetchAssignedTasks();
      execFetchUnassignedTasks();
      setFileSiderVisible(false);
    }
   

  }, [upladFileState.data])

  const renderTeams = () => {
    const teams = teamsState.data?.list;
    if (teams) {
      return teams.map((item) => {
        return (
          <TeamIcon
            name={item.name}
            id={item.id}
            hex={item.color}
            // selectedTeam={activeTeam}
            onClick={() => pickTeam(item.id)}
          />
        );
      });
    }
  };

  return (
    <div className=" sider-page">
      {(unassignedTasksState.loading ||
        assignedTasksState.loading ||
        startedTasksState.loading) && <Spinner />}

      <div className="flex-right mb-20">
        <Button
          inline={true}
          title="Załaduj z pliku"
          color="blue-color"
          className="mr-5"
          onClick={() => setFileSiderVisible(true)}
        />
        <Button
          inline={true}
          title="Dodaj zadanie"
          color="blue-color"
          onClick={() => setNewTaskSiderVisible(true)}
        />
      </div>

      <Tabs
        selectedIndex={currentTab}
        onSelect={(e) => {
          execFetchStats();
          setCurrentTab(e);
        }}
      >
        <TabList className={`tablist`}>
          <Tab>
            <h2>
              Nieprzydzielone: {statsState.data?.undelegated || 0}{" "}
            </h2>
          </Tab>
          <Tab>
            <h2 >
              Przydzielone: {statsState.data?.pending || 0}
            </h2>
          </Tab>
          <Tab>
            <h2 >Rozpoczęte: {statsState.data?.open || 0}</h2>
          </Tab>
          <Tab>
            <h2 >Zakończone: {statsState.data?.closed || 0}</h2>
          </Tab>
          {currentTab === 0 &&
            <div className={`team-tab`}>
              <div className={`team-scroll`}>{renderTeams()}

                {/* {renderTeams()}
              {renderTeams()}
              {renderTeams()}
              {renderTeams()} */}
              </div>
            </div>
          }
        </TabList>
        <TabPanel>
          <UnassignedTasks
            fetchData={execFetchUnassignedTasks}
            onToggleEdit={onEditSelected}
            tasks={unassignedTasksState.data}
            onSubmitSelected={onSubmitSelected}
            setSelectedValues={setSelectedValues}
          />
        </TabPanel>
        <TabPanel>
          <AssignedTasks
            fetchData={execFetchAssignedTasks}
            tasks={assignedTasksState.data}
          />
        </TabPanel>
        <TabPanel>
          <AssignedTasks
            fetchData={execFetchStartedTasks}
            tasks={startedTasksState.data}
          />
        </TabPanel>
        {/* <TabPanel>
          <TodoTasks
            unassignedTasks={unassignedTasksState.data}
            assignedTasks={assignedTasksState.data}
            startedTasks={startedTasksState.data}
            execFetchAssignedTasks={execFetchAssignedTasks}
            execFetchStartedTasks={execFetchStartedTasks} 
          >
          
          </TodoTasks>
        </TabPanel> */}
        <TabPanel>
          <EndedTasks />
        </TabPanel>
      </Tabs>
      {fileSiderVisible && (
       <CSVForm setFileSiderVisible={setFileSiderVisible} onSubmit={submitFile}  />
      )}
      {newTaskSiderVisible && (
        <div className="sider fixed">
          <CloseIcon
            className="sider-close"
            onClick={() => setNewTaskSiderVisible(false)}
          />
          <TaskForm mode="new" onSubmit={onCreateSubmit} />
        </div>
      )}
      {assignTaskSiderVisible && (
        <div className="sider fixed">
          <CloseIcon
            className="sider-close"
            onClick={() => setAssignTaskSiderVisible(false)}
          />
          <TaskForm
            taskIds={taskIds}
            preselectedTeamId={activeTeam}
            onSubmit={onAssignSubmit}
            mode="assign"
          />
        </div>
      )}
      {editTaskSiderVisible && (
        <div className="sider fixed">
          <CloseIcon
            className="sider-close"
            onClick={() => setEditTaskSiderVisible(false)}
          />
          <TaskForm taskIds={taskIds} onSubmit={onEditSubmit} mode="edit" />
        </div>
      )}
    </div>
  );
};

export default Tasks;
